import './user.less';

import {sendApiRequest, globalErrorHandler} from '../../js/api';

import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

import {changePassword} from '../password/changePw';

const dayjs = require('dayjs');

export class User {

    constructor(userInfoData){

        this.wrapper = document.querySelector('.user-control');

        // 由 API user/info 所取得的資料
        this.userInfoData = userInfoData.data;

        // 儲存整理後的使用者資料
        this.userRole = {
            // authcheck // 是否登入，true: 已登入、false: 未登入
            // allRoles  // 此人所有的管理權限 (Object)

            // // 以下是目前作用中管理權限的相關資料
            // id        // 管理權限 id (manager id)
            // role      // 管理權限英文代碼 ex: com
            // roleTitle // 管理權限的文字名稱 ex: 公司管理員

            // currentComId     // 管理權限所屬公司 id
            // currentComSname  // 公司簡稱
            // currentSiteId    // 管理權限所屬站點 id
            // currentSiteSname // 站點簡稱

            // menu // 管理權限的主選單設定
            // note // 管理權限的自訂備註
        }

        // 統計所擁有權限的數量
        this.roleCount = 0;

        // 整理資料並設定使用者選單的內容
        this.setUserMenu();

        // add event handler
        this.events();
    }


    /* ----- get 目前使用者的登入狀態與資訊 ----- */
    get userRoleInfo(){
        return this.userRole;
    }

    /* ----- get API 取得的原始資料 ----- */
    get rawData(){
        return this.userInfoData;
    }


    /* ----- 整理資料並設定使用者選單的內容 ----- */
    setUserMenu(newUserData = null){
        // newUserData: 可選擇傳入新的使用者權限資料
        // 因為在 managers.js 中有可能改變自己的權限，可用來更新目前的使用者選單
        if(newUserData){
            this.userInfoData = newUserData.data;
        }

        // 將資料整理紀錄在 this.userRole 中
        this.userRole = {
            authcheck: this.userInfoData.authcheck,
            id: this.userInfoData.user.active_manager_id,
        };

        // 管理權限的 code, title 對照表
        const roleMap = window.langTxt.account.roles;

        // 將所有權限整理到 allRoles 中
        const credentials = this.userInfoData.user.managers;

        // 統計所擁有權限的數量
        let count = 0;

        // 使用者可使用的最高權限，預設為 4 (sm 站點管理)
        let highRole = 4;

        // allRoles 值設定為 null (沒有任何權限)
        this.userRole.allRoles = null;

        if(credentials && credentials.length > 0){

            this.userRole.allRoles = {
                // cem:[], dm:[], com:[], sm:[], tm:[], regular: []
            };

            credentials.forEach((credItem) => {

                // 根據權限代碼判斷最高權限，數字越小權限越高
                if(credItem.types < highRole){
                    highRole = credItem.types;
                }

                let typeValue = `${credItem.types}`;
                if(!roleMap[typeValue]){
                    typeValue = 4;
                }

                const comData = credItem.company;
                const siteData = credItem.site;

                // 如果權限所屬的公司或站點被錯誤刪除，則視為無效權限
                if(!comData || !siteData){
                    // 如果目前作用中的是無效權限
                    if(this.userRole.id === credItem.id){
                        try {
                            // 使用中的權限發生錯誤，無效的公司或站點，請切換其他權限或綁定新權限
                            const errMsg = window.langTxt.user.error.msg1;
                            showFeedbackMsg(errMsg, 'fail', 'permanent');
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    return;
                }

                // 要整理到 allRoles 中的各別權限資料
                const role = {
                    active: false,
                    role: roleMap[typeValue]['code'], // 權限代碼 ex: com
                    id: credItem.id, // 管理權限 id (manager id)
                    title: roleMap[typeValue]['title'], // 管理權限的文字名稱 ex: 公司管理員
                    companyId: comData.id, // 所屬公司 id
                    company: comData.sname, // 所屬公司的簡稱 (sname)
                    siteId: siteData.id, // 所屬站點 id
                    site: siteData.sname, // 所屬站點的簡稱 (sname)
                    note: (credItem.note && credItem.note.length > 0)? credItem.note : null
                }

                // 如果是目前作用中的權限
                if(credItem.id === this.userRole.id){
                    role.active = true;

                    this.userRole['currentComId'] = comData.id;
                    this.userRole['currentComSname'] = comData.sname;

                    this.userRole['currentSiteId'] = siteData.id;
                    this.userRole['currentSiteSname'] = siteData.sname;

                    this.userRole['menu'] = credItem.menu;
                    this.userRole['note'] = (role.note)? role.note : null;
                }

                if(!this.userRole.allRoles[role.role]){
                    this.userRole.allRoles[role.role] = [];
                }

                this.userRole.allRoles[role.role].push(role);

                count += 1; // 所擁有權限的數量
            });
        }

        this.roleCount = count;

        // 以下 highRole 跟 activeTypeValue 的預設值可設定為 4
        // 1: cem, 2: dm, 3: com, 4: sm
        // 如果使用者實際上並無任何權限的話，會在 common.js 中檢查 userRoleInfo.allRoles 是否有效
        // 如果 userRoleInfo.allRoles 為 null 會導到 /user/match 頁

        // 使用者可使用的最高權限
        this.userRole['highRole'] = highRole;
        // 使用者可使用的最高權限文字代碼，ex: 'cem'
        this.userRole['highRoleCode'] = roleMap[`${highRole}`]['code'];

        // 目前作用中的權限 (Number)，如果沒有任何權限則預設為 4 (站點管理)
        let activeTypeValue = this.userInfoData.user.active_manager_type || 4;

        // 若只有 com/sm 權限時，則用 high role 取代 active_manager_type
        // 這項規則的前提是假設大部分使用者的 com/sm 權限不會跨公司，否則會造成一些問題
        // 例如擁有 A 公司的 com 權限，B 公司的 sm 權限，此時就會被視為同時擁有兩間公司的 com 權限
        //
        // update: 因為採用漸進式更新，目前還需要保留切換權限選單，所以先不使用此規則
        // if(highRole > 2){
        //     activeTypeValue = highRole;
        // }

        if(roleMap[activeTypeValue]){
            this.userRole['role'] = roleMap[activeTypeValue]['code']; // ex: com
            this.userRole['roleTitle'] = roleMap[activeTypeValue]['title']; // ex: 公司管理
        }

        // if(window.env === 'dev'){
        //     console.log(this.userRole);
        // }

        // 使用者登入後才繼續執行以下階段
        if(this.userRole.authcheck){
            this.show();
        }

    }

    /* ----- 在 header 顯示使用者資訊 ----- */
    show(){

        const accountTxt = window.langTxt.account;

        const userRole = this.userRole;

        const currComSiteName =
        `${userRole.currentComSname}《${userRole.currentSiteSname}》`;

        // 注意：當使用者有 cem/dm 權限時，才需要顯示與權限相關的 UI
        // const highRoleCode = userRole['highRoleCode'] || 'sm';

        // let showRoleMenu = false;

        // if(highRoleCode === 'cem' || highRoleCode === 'dm'){
        //     showRoleMenu = true;
        // }

        // 改用漸進式調整，先一律顯示切換權限相關的 UI
        const showRoleMenu = true;

        // TEST
        // showRoleMenu = true;

        // 1. 在 menu 中顯示目前作用中權限及所屬公司站點 (有可能無任何管理權限)
        // 並且用來開啟切換權限選單 (menu trigger)
        let roleTrigger = '';

        // 2. 切換權限選單
        let switchRoleMenu = '';

        // 3. 在 header 顯示目前作用中權限及所屬站點 (有可能無任何管理權限)
        let roleStatus = '';

        // 以上 1~3 只有當使用者有 cem/dm 權限才需要顯示
        if(showRoleMenu){

            // 1. menu trigger
            if(userRole.id && userRole.allRoles){
                roleTrigger =
                `<div class="group roleTrigger">
                    <div class="role-text">${currComSiteName}</div>
                    <div class="hint">${accountTxt.control.switch}</div>
                    <svg class="down">
                        <use xlink:href="/assets/sprites/sprites_solid.svg#angle-down"></use>
                    </svg>
                </div>`;
            }

            // 2. 切換權限選單
            let menuContent = '';
            const rolesTable = accountTxt.roles;

            // 依序檢查是否有權限
            // order: ['1', '2', '3', '4', '5', '0'],
            rolesTable.order.forEach((orderItem) => {

                // cem, dm, com, sm
                const roleCode = rolesTable[orderItem]['code'];

                // 如果有 allRoles[roleCode]，表示有這種權限，數量可能是一個以上
                // 如果 allRoles[roleCode] 是 undefined 表示沒有這種權限
                let roleArr = null;
                try {
                    roleArr = userRole.allRoles[roleCode];
                } catch(error){
                    return; // 略過此項
                }

                if(!roleArr){
                    return;
                }

                // 此權限只有一個
                if(roleArr.length === 1){
                    menuContent +=
                    this._roleSelector(roleArr[0]);
                    return;
                }

                // 同樣的權限如果有兩個以上需要有子選單
                let subMenuTitle = '';
                let subMenuList = '';
                const subMenuLength =
                `<span class="length">${roleArr.length}</span>`;

                let active = false; // 用來記錄是否含有 active 的子項目

                roleArr.forEach((role, index) => {
                    if(index == 0){
                        subMenuTitle = role.title; // 利用第一筆資料帶出權限名稱
                    }
                    if(role.active){
                        active = true; // 含有 active 的項目
                    }
                    subMenuList += this._roleSelector(role, true);
                });

                const activeClass = active? ' active' : '';

                menuContent +=
                `<div class="role-submenu${activeClass}">
                    <div class="role-submenu-title">
                        ${subMenuTitle}${subMenuLength}
                    </div>
                    <div class="role-submenu-list">${subMenuList}</div>
                </div>`;

            });

            switchRoleMenu =
            `<div class="switch-role-menu group">
                ${menuContent}
            </div>`;

            // 3. 在 header 顯示目前作用中權限及所屬站點
            if(userRole.id && userRole.allRoles){
                let roleNote = '';
                if(userRole.note && userRole.note.length > 0){
                    roleNote = `<div class="slot note">${userRole.note}</div>`;
                }

                roleStatus =
                `<div id="headRoleStatus">
                    <div class="innerbox">
                        ${roleNote}
                        <div class="slot site">
                            <span>${userRole.currentComSname}</span>
                            <span>《${userRole.currentSiteSname}》</span>
                        </div>
                        <div id="menuTriggerAlt" class="slot role">${userRole.roleTitle}</div>
                    </div>
                </div>`;
            }

        } else {
            // showRoleMenu === false
            // 只在 header 顯示目前最高權限 (high role) 名稱
            // 需排除尚未綁定任何權限的狀況
            if(userRole.id && userRole.allRoles){
                roleStatus =
                `<div id="headRoleStatus">
                    <div class="innerbox">
                        <div class="slot role">${userRole.roleTitle}</div>
                    </div>
                </div>`;
            }
        }

        // 優先顯示 name，如果沒有的話顯示 email
        let displayName = '';

        const userName = this.userInfoData.user.name;
        const userEmail = this.userInfoData.user.email;

        if(userName){
            displayName = `<div class="name">${userName}</div>`;
        } else {
            displayName = `<div class="email">${userEmail}</div>`;
        }

        // header 小頭像，點選後開啟使用者選單
        const menuTrigger =
        `<div id="menuTrigger" class="avatar">
            <div class="icon"></div>
        </div>`;

        // 使用者選單中的大頭像
        let avatarImg = '';

        // 如果沒有頭像的話，用 name 或 email 的第一個字代替
        const avatarSource = this.userInfoData.user.avatar;

        if(avatarSource){
            // 有頭像資料
            avatarImg =
            `<div class="avatar" style="background-image:url('${avatarSource}')"></div>`;

        } else {
            // 沒有頭像資料，顯示大寫首字母
            let firstLetter = '';

            if(userName){
                firstLetter = userName.slice(0,1).toUpperCase();
            } else {
                firstLetter = userEmail.slice(0,1).toUpperCase();
            }

            avatarImg = `<div class="avatar empty">${firstLetter}</div>`;
        }

        // 是否只有一個管理權限
        const singleRoleClass = (this.roleCount < 2)? ' class="single"' : '';

        // 帳號綁定按鈕
        // const bindBtn =
        // `<a class="btn-userMatch" href="/user/match">
        //     <svg>
        //         <use xlink:href="/assets/sprites/sprites_solid.svg#link"></use>
        //     </svg>${accountTxt.control.match}
        // </a>`;

       // 切換語言選單
        const langMenu = this._createLangMenu();

        const Html =
        `${roleStatus}
        ${menuTrigger}

        <div id="userMenu"${singleRoleClass}>

            <div class="close-overlay"></div>

            <div class="menuwpr">
                <div class="group basic-info">
                    ${avatarImg}
                    ${displayName}
                    <small class="email">${userEmail}</small>
                </div>
                ${roleTrigger}
                ${switchRoleMenu}
                ${langMenu}
                <div class="group btnwpr">
                    <div id="changePassword" class="btn-user">
                        ${accountTxt.control.changePassword}
                    </div>
                    <div id="userLogout" class="btn-user">
                        ${accountTxt.control.logout}
                    </div>
                </div>
            </div>

        </div>
        `;

        this.wrapper.innerHTML = Html;

        this.wrapper.classList.add('show');

        this.checkPasswordUpdated();

    } // show

    // 切換語言選單
    _createLangMenu(){

        const currLang = document.body.dataset.lang;

        const langTxt = window.langTxt.language;

        function btn(lang){
            const html =
            `<div class="btn ${lang} lang-btn${(currLang === lang)? ' current' : ''}" data-lang="${lang}">${langTxt[lang]}</div>`;
            return html;
        }

        const langMenu =
        `<div class="lang-menu">
            ${btn('tc')}
            ${btn('jp')}
            ${btn('en')}
        </div>`;

        return langMenu;
    }


    /* ----- role selector HTML ----- */
    _roleSelector(Data, subMenu = false){
        // Data: this.userRole.allRoles 中的單一項目資料
        // subMenu: 是否為子選單，子選單的項目不顯示權限名稱，只顯示跟公司站點

        let current = (Data.active)? ' current' : '';

        const companyName = (Data.company)? Data.company : ''; // 公司簡稱
        const siteName = (Data.site)? Data.site : ''; // 站點簡稱

        const comSiteName = `${companyName}《${siteName}》`;

        const note =
        (Data.note)? `&nbsp;/&nbsp;${Data.note}` : '';

        // let content = '';
        // if(subMenu){
        //     content = comSiteName;
        // } else {
        //     content =
        //     `<div class="role-title">
        //         <span class="role">${Data.title}${note}</span>
        //         <span class="site-name">${comSiteName}</span>
        //     </div>`;
        // }

        const content =
        `<div class="role-title">
            <span class="role">${Data.title}${note}</span>
            <span class="site-name">${comSiteName}</span>
        </div>`;

        const Html =
        `<div class="role-selector${current}" data-type="${Data.role}" data-id="${Data.id}">
            <svg>
                <use xlink:href="/assets/sprites/sprites_solid.svg#angle-right"></use>
            </svg>
            ${content}
        </div>`;

        return Html;
    }

    /* ----- 開啟使用者選單 ----- */
    _openUserMenu(){

        this._resetRoleMenu();

        this.wrapper.querySelector('#userMenu').classList.add('open');
    }

    /* ----- 關閉使用者選單 ----- */
    _closeUserMenu(){

        this.wrapper.querySelector('#userMenu').classList.remove('open');

        setTimeout(() => {
            // 400ms 後關閉權限選單 (配合 transition 的時間)
            this._resetRoleMenu();
        }, 400);
    }

    /* ----- 將權限選單恢復為預設狀態 (關閉) ----- */
    _resetRoleMenu(){

        // roleTrigger 按鈕
        const trigger = this.wrapper.querySelector('.roleTrigger');
        if(trigger){
            trigger.classList.remove('open');
        }

        // 權限選單 (有可能不存在)
        const menu = this.wrapper.querySelector('.switch-role-menu');
        if(menu){
            menu.classList.remove('expand');
        }
    }


    /* ----- user menu, event handler ----- */
    events(){

        this.wrapper.addEventListener('click',(event) => {

            // 開啟、關閉使用者選單
            if(event.target.id === 'menuTrigger' || event.target.id === 'menuTriggerAlt'){
                if(userMenu.classList.contains('open')){
                    // 由開啟狀態關閉
                    this._closeUserMenu();
                } else {
                    // 由關閉狀態開啟
                    this._openUserMenu();
                }
            }

            // 點擊 overlay 開啟關閉選單
            if(event.target.classList.contains('close-overlay')){
                this._closeUserMenu();
            }

            // 開啟、關閉切換權限選單
            if(event.target.classList.contains('roleTrigger')){

                if(this.roleCount < 2){
                    return;
                }

                const target = event.target;
                const switchMenu = this.wrapper.querySelector('.switch-role-menu');
                if(switchMenu){
                    if(target.classList.contains('open')){
                        // 關閉
                        this._resetRoleMenu();
                    } else {
                        // 開啟
                        target.classList.add('open');
                        switchMenu.classList.add('expand');
                    }
                }
            }

            // 切換管理身份
            if(event.target.classList.contains('role-selector')){
                const target = event.target;
                this.switchRole(
                    target.dataset.type,
                    target.dataset.id
                );
            }

            // 登出
            if(event.target.id === 'userLogout'){
                this.logout();
            }

            // 切換語言
            if(event.target.classList.contains('lang-btn')){
                const target = event.target;
                localStorage.setItem('user-lang', target.dataset.lang);
                location.reload();
            }

            // 更改密碼
            if(event.target.id === 'changePassword'){
                // 建立一個新的實體，儲存在 window 變數中
                // 當完成或取消操作後需清空此 window 變數
                window.changePwBox = new changePassword('changePwBox');
                // 關閉使用者選單
                this._closeUserMenu();
            }

        });

        this.wrapper.addEventListener('mouseover', (event) => {

            if(event.target.classList.contains('role-submenu-title')){
                // 需要檢查與設定 role-submenu-list 最大高度
                // 避免內容過長時超出畫面可操作範圍
                try {
                    const parentNode = event.target.parentNode;
                    const submenu = parentNode.querySelector('.role-submenu-list');
                    const bounding = submenu.getBoundingClientRect();
                    // 與頂端的距離
                    const top = Math.round(bounding.top);
                    // 元素本身的高度
                    // const height = submenu.offsetHeight;

                    // viewport 高度
                    const wh =
                        window.innerHeight ||
                        document.documentElement.clientHeight ||
                        document.body.clientHeight;

                    // 子選單最大高度 (底部預留 20px 空間)
                    const maxHeight = wh - top - 20;

                    submenu.style.maxHeight = `${maxHeight}px`;

                } catch(error){
                    console.log(error);
                }
            }

        });

        // TEST 測試
        // window.changePwBox = new changePassword('changePwBox');
    }

    // 送出切換管理身份請求
    async switchRole(type, id){

        document.body.classList.add('loading');

        let apiResult = null;
        try {
            apiResult = await sendApiRequest('post', '/user/switchaccount', {
                type: type,
                manager_id: id * 1
            })
            .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
        }

        document.body.classList.remove('loading');

        if(apiResult.ajaxcode === 0){
            // 清除 localStorage 資料
            clearLocalStorage();
            // 導到首頁
            location.replace(`/`);
        } else {
            console.log(apiResult);
        }

    }

    // 送出登出請求
    async logout(){

        document.body.classList.add('loading');

        let apiResult = null;
        try {
            apiResult = await sendApiRequest('post', '/user/logout', null)
                .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
        }

        document.body.classList.remove('loading');

        if(apiResult.ajaxcode === 0){
            // 清除所有已存的資料
            clearLocalStorage();
            location.replace('/');
        } else {
            console.log(apiResult);
        }
    }

    // ----- 檢查上次密碼更新時間 ----- //
    checkPasswordUpdated(){
        try {
            // 上次更新密碼的時間 ex: 2024-07-22 20:38:11
            const lastUpdated = this.userInfoData.user.password_updated_at;

            const diff = dayjs().diff(lastUpdated, 'day');

            if(window.env === 'dev'){
                console.log(`${diff} 天未改密碼`);
            }

        } catch(error){
            console.log(error);
        }
    }

} // end of class User

/* ----- 清除 localStorage，但保留一些必要的資訊 ----- */
export function clearLocalStorage(){

    // 需要保留的資料 localStorage key
    const reserve = [
        'user-lang', // 使用者選擇的語言
        'login-account', // 成功登入的帳號
        'records-site-type', // 紀錄查詢「多人模式受測者」站點類別
        'records-counts-panel', // 紀錄查詢「countsPanel 狀態」(開啟或關閉)
    ]

    const data = {};

    reserve.forEach((key) => {
        const currData = localStorage.getItem(key);
        if(currData){
            data[key] = currData;
        }
    });

    localStorage.clear(); // 清除所有已存的資料

    for(const prop in data){
        localStorage.setItem(prop, data[prop]);
    }

}